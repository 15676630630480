import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as yup from "yup";

import { Banner } from "../../components/banner";
import styled from "styled-components";
import { ButtonWrapper } from "../../components/buttonWrapper";
import {
  CheckboxContainer,
  FormContainer,
} from "../../components/formContainer";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const Form: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const match = useRouteMatch<{ name: string; guests?: string }>(
    "/registration/:guests?/event/:name"
  );
  const name = match?.params.name || "ncv";
  const registeringTo = name.split("_");
  const prettyName = registeringTo.map((s) => s.toUpperCase()).join(" + ");

  const schema = yup.object().shape({
    ...(registeringTo.includes("ncv") && {
      studentCard: yup
        .mixed()
        .test(
          "required",
          "You need to provide a file",
          (value) => value && value.length
        )
        .test(
          "fileSize",
          "The file is too large",
          (value) => value && value[0] && value[0].size <= 5000000
        )
        .test(
          "type",
          "We only support jpeg, png, pdf",
          (value) =>
            value &&
            value[0] &&
            ["application/pdf", "image/jpeg", "image/png"].includes(
              value[0].type
            )
        ),
    }),
    firstName: yup.string().required("Missing field"),
    lastName: yup.string().required("Missing field"),
    email: yup.string().email("Invalid email"),
    mobileNumber: yup.string().matches(phoneRegExp, "Phone number invalid"),
    acceptTerms: yup.bool().oneOf([true], "Field must be checked"),
    isAdult: yup.bool().oneOf([true], "Field must be checked"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: any) => {
    // TODO: send data to the backend
    console.log(data);
    history.push(`/registration/user/${data.email}`);
  };

  return (
    <div>
      <Banner title={t("navbar.registration")} />
      <div className="margin">
        <ChosenEvent>Registration to the {prettyName}</ChosenEvent>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {/* First Name */}
          <label htmlFor="name">First Name</label>
          <input type="text" id="fname" {...register("firstName")} />
          {errors.firstName && <p>{errors.firstName.message}</p>}

          {/* Last Name */}
          <label htmlFor="lastName">Last Name</label>
          <input type="text" id="lname" {...register("lastName")} />
          {errors.lastName && <p>{errors.lastName.message}</p>}

          {/* Gender */}
          <label>Gender</label>
          <select {...register("gender")}>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          {/* Email */}
          <label htmlFor="email">Email</label>
          <input type="email" {...register("email")} />
          {errors.email && <p>{errors.email.message}</p>}

          {/* Phone Number */}
          <label htmlFor="phone">Phone Number</label>
          <input {...register("mobileNumber")} />
          {errors.mobileNumber && <p>{errors.mobileNumber.message}</p>}

          {/* CS Student */}
          <CheckboxContainer>
            <input type="checkbox" {...register("centraleSupelecStudent")} />
            <label>I'm a student at CentraleSupélec</label>
          </CheckboxContainer>

          <h3>Information related to the event</h3>

          {/* Level */}
          {registeringTo.includes("ncv") && (
            <>
              <label>Lead climbing level (on sight)</label>
              <select {...register("leadLevel")}>
                <option value="1">4a to 5c</option>
                <option value="2">6a to 6c</option>
                <option value="3">7a</option>
                <option value="4">7b</option>
                <option value="5">7c</option>
                <option value="6">8a</option>
                <option value="7">8b</option>
                <option value="8">8c / 9a</option>
              </select>
            </>
          )}
          <label>Bouldering level</label>
          <select {...register("boulderLevel")}>
            <option value="1">4a to 5c</option>
            <option value="2">6a / 6b</option>
            <option value="3">6c / 7a</option>
            <option value="4">7b</option>
            <option value="5">7c</option>
            <option value="6">8a</option>
            <option value="7">8b</option>
            <option value="8">8c</option>
          </select>

          {/* Sandwich */}
          {registeringTo.includes("ncv") && (
            <>
              <label>Sandwich for the NCV</label>
              <select {...register("sandwichNCV")}>
                <option value="option1">Whatever</option>
                <option value="option2">Option 2</option>
                <option value="none">None</option>
              </select>
            </>
          )}
          {registeringTo.includes("cvg") && (
            <>
              <label>Sandwich for the CVG</label>
              <select {...register("sandwichCVG")}>
                <option value="option1">Whatever</option>
                <option value="option2">Option 2</option>
                <option value="none">None</option>
              </select>
            </>
          )}

          {/* T-Shirt size */}
          <label>T-Shirt size</label>
          <select {...register("tshirtSize")}>
            <option value="xs">XS</option>
            <option value="s">S</option>
            <option value="m">M</option>
            <option value="l">L</option>
            <option value="xl">XL</option>
          </select>

          {/* Student Card */}
          {registeringTo.includes("ncv") && (
            <>
              <label>Upload your student card (less than 5Mo)</label>
              <input type="file" {...register("studentCard")} />
              {errors.studentCard && <p>{errors.studentCard.message}</p>}
            </>
          )}

          {/* Accepting terms */}
          <h3>Terms</h3>

          <CheckboxContainer>
            <input
              type="checkbox"
              {...register("isAdult", { required: true })}
            />
            <label>I am at least 18 years old</label>
          </CheckboxContainer>
          {errors.isAdult && <p>{errors.isAdult.message}</p>}

          <CheckboxContainer>
            <input
              type="checkbox"
              {...register("acceptTerms", { required: true })}
            />
            <label>
              I agree to have my picture taken during the event, to respect the
              instructions given by the organizers at the start of the event, as
              well as to present my health pass at the organizers' request.
            </label>
          </CheckboxContainer>
          {errors.acceptTerms && <p>{errors.acceptTerms.message}</p>}

          {/* Submit button */}
          <ButtonWrapper>
            <input type="submit" />
          </ButtonWrapper>
        </FormContainer>
      </div>
    </div>
  );
};

const ChosenEvent = styled.h3`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  padding-bottom: 15px;
`;
