import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

export interface IProtectedRoute {
  component: React.ComponentType;
  path: string | string[];
  condition: boolean | (() => Promise<boolean>) | (() => boolean);
  exact?: boolean;
  fallback?: React.ReactElement;
}

export const ProtectedRoute: React.FC<IProtectedRoute> = ({
  component,
  path,
  condition,
  exact = false,
  fallback,
}) => {
  const [display, setDisplay] = useState<boolean>();

  useEffect(() => {
    if (typeof condition === "function") {
      const res = condition();
      if (typeof res === "boolean") {
        setDisplay(res);
      } else {
        res.then((d) => {
          setDisplay(d);
        });
      }
    } else {
      setDisplay(condition);
    }
  }, [condition]);

  if (display === true) {
    return <Route component={component} path={path} exact={exact} />;
  } else if (display === false) {
    return fallback ? fallback : null;
  } else {
    return null;
  }
};
