const events = {
  ncv: {
    descriptions: [
      { urls: ["1.jpg", "2.jpg", "3.jpg"], key: "comp" },
      { urls: ["4.jpg", "5.jpg", "6.jpg"], key: "finals" },
      { urls: ["7.jpg", "8.jpg", "9.jpg"], key: "food" },
    ],
  },
  cvg: {
    descriptions: [
      { urls: ["1.jpg", "2.jpg", "3.jpg"], key: "contest" },
      { urls: ["4.jpg", "5.jpg", "6.jpg"], key: "international" },
      { urls: ["7.jpg", "8.jpg", "9.jpg"], key: "money" },
    ],
  },
  obs: {
    descriptions: [
      { urls: ["1.jpg", "2.jpg", "3.jpg"], key: "contest" },
      { urls: ["4.jpg", "5.jpg", "6.jpg"], key: "barbecue" },
      { urls: ["7.jpg", "8.jpg", "9.jpg"], key: "game" },
    ],
  },
  vt: {
    descriptions: [
      { urls: ["1.jpg", "2.jpg", "3.jpg"], key: "team" },
      { urls: ["4.jpg", "5.jpg", "6.jpg"], key: "building" },
      { urls: ["7.jpg", "8.jpg", "9.jpg"], key: "students" },
    ],
  },
};

const acronyms = Object.keys(events);

export type eventValues = "ncv" | "cvg" | "obs" | "vt";

export { events, acronyms };
