import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { events, eventValues } from "../config/events";

import { Banner } from "../components/banner";
import styled from "styled-components";
import { IColors } from "../styles/theme";
import { ColoredSpan } from "../components/misc";

export const EventDescription: React.FC = () => {
  const { t } = useTranslation();

  const isSmallDisplay = useMediaQuery({ query: "(max-width: 675px)" });

  const match = useRouteMatch<{ acronym: string }>("/event/:acronym");
  const acronym = match?.params.acronym;

  const color1 = `${acronym}1` as keyof IColors;
  const color2 = `${acronym}2` as keyof IColors;

  return (
    <div>
      <Banner title={t("navbar.events")} />
      <EventList className="centered">
        {Object.keys(events).map((event) => (
          <NavLink
            to={`/event/${event}`}
            key={event}
            activeClassName="route-is-active"
          >
            <p>
              {isSmallDisplay
                ? t(`overview.${event}.acronym`)
                : t(`overview.${event}.title`)}
            </p>
          </NavLink>
        ))}
      </EventList>
      <SmallHeaderContainer>
        <img
          src={`/assets/images/overview/gradiants/${acronym}.png`}
          alt="event logo"
        />
        <h1>
          <ColoredSpan color={color1}>
            {t(`overview.${acronym}.title`)}
          </ColoredSpan>
          <ColoredSpan color={color2}>.</ColoredSpan>
        </h1>
      </SmallHeaderContainer>
      <DescriptionContainer>
        {events[acronym as eventValues].descriptions.map(({ urls, key }) => (
          <DescriptionUnit key={key}>
            <TextUnitContainer>
              <h2>{t(`descriptions.${acronym}.${key}.title`)}</h2>
              <p>{t(`descriptions.${acronym}.${key}.text`)}</p>
            </TextUnitContainer>
            <ImagesContainer>
              {urls.map((url) => (
                <img
                  src={`/assets/images/events/${acronym}/${url}`}
                  alt={`image_${acronym}`}
                  key={url}
                />
              ))}
            </ImagesContainer>
          </DescriptionUnit>
        ))}
      </DescriptionContainer>
    </div>
  );
};

const EventList = styled.div`
  background-color: ${({ theme }) => theme.colors.footer};

  & p {
    padding: 10px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

const SmallHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 30px 0px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h1 {
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.bold};
  }

  & img {
    height: 100px;
    margin-right: 30px;
  }
`;

const DescriptionContainer = styled.div`
  > :nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.white};
  }

  > :nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.header};

    & h2,
    p {
      color: ${({ theme }) => theme.colors.grey0};
    }
  }
`;

const DescriptionUnit = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 10px;
  justify-content: center;
  align-items: center;
`;

const TextUnitContainer = styled.div`
  max-width: 800px;
  padding: 5px;

  & h2 {
    text-align: center;
  }

  & p {
    text-align: justify;
    margin: 20px 0;
    color: ${({ theme }) => theme.colors.grey1};
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  flex-wrap: wrap;

  & img {
    width: 30%;
    max-width: 400px;
    border-radius: ${({ theme }) => theme.sizes.border_radius};
    margin: 10px;
    @media screen and (max-width: 1023px) {
      width: 38%;
    }
  }
  @media screen and (max-width: 675px) {
    flex-direction: column;
    & img {
      width: 90%;
      margin: 10px auto;
    }
  }
`;
