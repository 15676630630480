import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { IColors } from "../styles/theme";

const ColoredSpan = styled.span<{ color: keyof IColors }>`
  color: ${({ theme, color }) => theme.colors[color]};
`;

// This div parent needs 'position: absolute' to make it work as expected
const BubbleTopContainer = styled.div<{ color?: keyof IColors }>`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0px ${({ theme }) => theme.sizes.small_padding};
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme, color }) => theme.colors[color || "ncv1"]};
  color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.sizes.bubble_height};
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.sizes.border_radius};
`;

const ScrollToTop: React.FC = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export { ColoredSpan, ScrollToTop, BubbleTopContainer };
