import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonWrapper } from "../components/buttonWrapper";
import { Banner } from "../components/banner";
import { partners } from "../config/partners";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Partnerships: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Banner title={t("navbar.partnerships")} />
      <div className="centered margin">
        <ButtonWrapper>
          <Link to={{ pathname: "../assets/catalog.pdf" }} target="_blank">
            {t("partnerships.catalog")}
          </Link>
        </ButtonWrapper>
      </div>
      <PartnersContainer className="partners">
        {Object.keys(partners).map((key) => (
          <div className="partner" key={key}>
            <img
              src={`assets/images/partners/${key}.png`}
              alt={`partner ${key}`}
            />
            <div>
              <h2>{partners[key]}</h2>
              <p>{t(`partnerships.${key}`)}</p>
            </div>
          </div>
        ))}
      </PartnersContainer>
    </div>
  );
};

const PartnersContainer = styled.div`
  & :not(:last-of-type):after {
    content: "";
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 80%;
    opacity: 0.3;
    border-bottom: 2px solid ${({ theme }) => theme.colors.ncv1};
  }

  & .partner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 60px 0px;
    margin: 0px 60px auto;
    min-height: 100px;
    align-items: center;

    & img {
      width: 200px;
      height: auto;
      margin-right: 100px;
    }

    & > div {
      width: 70%;
      display: flex;
      flex-direction: column;
    }

    & p {
      text-align: justify;
    }
  }

  @media screen and (max-width: 675px) {
    .partner {
      flex-direction: column;
      margin: 0px 30px auto;

      & > div {
        width: 100%;
      }

      & img {
        margin: 0 0 25px 0;
      }
    }
  }
`;
