import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Banner } from "../components/banner";
import { BubbleTopContainer } from "../components/misc";

export const Photos: React.FC = () => {
  const { t } = useTranslation();
  const editions = [
    {
      year: 2023,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2023-2024",
    },
    {
      year: 2022,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2022-2023",
    },
    {
      year: 2021,
      url: "https://galerie.pics/galerie/week-end-centrale-vertical",
    },
    {
      year: 2019,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2019-2020",
    },
    {
      year: 2018,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2018-2019",
    },
    {
      year: 2017,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale",
    },
    {
      year: 2016,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2016-2017",
    },
    {
      year: 2015,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2015-2016",
    },
    {
      year: 2014,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2014-2015",
    },
    {
      year: 2013,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2013-2014",
    },
    {
      year: 2012,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2012-2013",
    },
    {
      year: 2011,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2011-2012",
    },
    {
      year: 2010,
      url: "https://galerie.pics/galerie/nuit-centrale-verticale-2010-2011",
    },
  ];

  return (
    <div>
      <Banner title={t("navbar.photos")} />
      <div className="margin">
        <h3>{t("photos.description")}</h3>
        <p>{t("photos.thanks")}</p>
        <EditionsContainer>
          {editions.map(({ year, url }) => (
            <EditionCard key={year} className="edition-card" href={url}>
              <BubbleTopContainer>
                {t("misc.edition")} {year}
              </BubbleTopContainer>
              <img
                src={`assets/images/editions/${year}.png`}
                alt="logo of the edition"
                className="logo-event"
              />
            </EditionCard>
          ))}
        </EditionsContainer>
      </div>
    </div>
  );
};

const EditionCard = styled.a`
  width: 280px;
  height: 180px;
  margin: 40px;
  border-radius: ${({ theme }) => theme.sizes.border_radius};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 15px ${({ theme }) => theme.colors.shadow1};
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  :hover {
    filter: brightness(95%);
  }
  @media screen and (max-width: 500px) {
    margin: 40px 0;
  }
  @media screen and (max-width: 310px) {
    width: 250px;
  }

  & img {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    height: 60%;
    width: auto;
    opacity: 0.7;
    filter: invert(100%);
  }
`;

const EditionsContainer = styled.div`
  margin: 50px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
