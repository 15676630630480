export const theme = {
  fonts: {
    light: "MontserratLight",
    main: "MontserratBook",
    bold: "MontserratBold",
  },
  sizes: {
    navbar_height: "50px",
    bubble_height: "30px",
    button_height: "40px",
    border_radius: "8px",
    small_padding: "10px",
  },
  colors: {
    background: "rgb(253,253,253)",
    header: "#223",
    footer: "#223",

    black: "#0a0a0a",
    white: "#fefefe",

    grey2: "rgb(60,60,60)",
    grey1: "rgb(90,90,90)",
    grey0: "#e2e2e2",

    red: "rgb(255, 0, 0)",
    green: "rgb(0, 161, 135)",

    blue2: "rgb(0, 24, 59)",
    blue1: "rgb(27,117,188)",
    blue0: "rgb(120,207,248)",

    ncv1: "rgb(38,34,98)",
    ncv2: "rgb(193,61,123)",

    cvg1: "rgb(20,20,20)",
    cvg2: "rgb(201,2,69)",

    obs1: "rgb(38,28,92)",
    obs2: "rgb(0, 161, 135)",

    vt1: "rgb(38,28,92)",
    vt2: "rgb(0, 161, 135)",

    shadow1: "rgba(0, 0, 0, 0.2)",
    shadow2: "rgba(0, 0, 0, 0.5)",

    gradient1: "rgb(63, 0, 92)",
    gradient2: "rgb(22, 65, 157)",
  },
};

export type IColors = typeof theme.colors;

export type ITheme = typeof theme;
