import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Banner } from "../components/banner";

export const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Banner title={t("misc.not_found")} />
      <NotFoundContainer className="margin">
        <h3>{t("misc.not_found_details")}</h3>
        <img
          src={"/assets/images/misc/not_found.png"}
          alt={"not found image"}
        />
      </NotFoundContainer>
    </div>
  );
};

const NotFoundContainer = styled.div`
  text-align: center;

  & img {
    width: 90vw;
    max-width: 600px;
  }
`;
