import styled from "styled-components";

export const FormContainer = styled.form`
  font-size: 14px;
  max-width: 600px;
  margin: auto;

  & h3 {
    margin-top: 30px;
  }

  & p {
    color: ${({ theme }) => theme.colors.red};
  }

  & input {
    width: 100%;
  }

  & label {
    line-height: 2;
    display: inline-block;
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.black};
  }

  & input[type="button"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }

  & input[type="submit"] {
    cursor: pointer;
  }

  & input[type="button"]:hover {
    transition: 0.3s all;
  }

  & input[type="button"] {
    -webkit-appearance: none;
  }
`;

export const CheckboxContainer = styled.div`
  display: block;
  margin-top: 20px;

  & input {
    display: inline;
    width: 20px !important;
  }

  & label {
    display: inline !important;
  }
`;
