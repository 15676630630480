import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Banner } from "../components/banner";

import { Ranking } from "../models/ranking";
import { getRanking } from "../utils/request";

export const Rankings: React.FC = () => {
  const { t } = useTranslation();

  const [rankings, setRankings] = useState<Ranking[]>();
  useEffect(() => {
    getRanking(2021, "boulder", true, false).then(setRankings); // TODO: link params with html option values
  }, []);

  return (
    <div>
      <Banner title={t("navbar.ranking")} />
      <div className="margin">
        <ChoicesContainer>
          <div>
            <PaddedParagraph>Année</PaddedParagraph>
            <select>
              <option value="2021">2021</option>
              <option value="2019">2019</option>
            </select>
          </div>
          <div>
            <PaddedParagraph>Évènement</PaddedParagraph>
            <select>
              <option value="cvg">Centrale Verticale Games</option>
              <option value="lead">Nuit Centrale Verticale - voie</option>
              <option value="boulder">Nuit Centrale Verticale - bloc</option>
            </select>
          </div>
          <div>
            <PaddedParagraph>Phase</PaddedParagraph>
            <select>
              <option value="finals">Finales</option>
              <option value="qualifs">Qualifications</option>
            </select>
          </div>
          <div>
            <PaddedParagraph>Sexe</PaddedParagraph>
            <select>
              <option value="man">Hommes</option>
              <option value="woman">Femmes</option>
            </select>
          </div>
        </ChoicesContainer>
        {rankings ? (
          <Table>
            <tr>
              <th>N°</th>
              <th>Name</th>
              <th>Details</th>
              <th>Score</th>
            </tr>
            {rankings.map((climber) => (
              <tr key={climber.lastName}>
                <td>{climber.rank}</td>
                <td>{`${climber.firstName} ${climber.lastName}`}</td>
                <td>
                  <DetailsContainer>
                    {climber.details.map((top, i) => (
                      <TopIcon top={top} key={`${i}_${climber.lastName}`} />
                    ))}
                  </DetailsContainer>
                </td>
                <td>{climber.score}</td>
              </tr>
            ))}
          </Table>
        ) : (
          <p>No ranking found with these settings</p>
        )}
      </div>
    </div>
  );
};

const ChoicesContainer = styled.div`
  @media screen and (min-width: 675px) {
    display: flex;

    & div + div {
      margin-left: 20px;
    }
  }
`;

const PaddedParagraph = styled.p`
  padding-top: 10px;
`;

const Table = styled.table`
  border-collapse: collapse;
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 2px 15px ${({ theme }) => theme.colors.shadow1};
  border-radius: ${({ theme }) => theme.sizes.border_radius};
  overflow: hidden;

  & th {
    text-align: left;
    background-color: ${({ theme }) => theme.colors.header};
    color: ${({ theme }) => theme.colors.white};
  }

  & tr > * {
    padding: 10px 0 10px 10px;
  }

  & tr:nth-child(2n + 1) {
    background-color: ${({ theme }) => theme.colors.grey0};
  }
`;

const TopIcon = styled.div<{ top: number }>`
  width: 20px;
  height: 20px;
  margin: 0 2px;
  border-radius: 3px;
  background-color: ${({ theme, top }) =>
    top === 2 ? theme.colors.green : theme.colors.white};
  position: relative;
  box-shadow: 0px 2px 15px ${({ theme }) => theme.colors.shadow1};
  overflow: hidden;

  :after {
    ${({ theme, top }) =>
      top === 1 &&
      `
      content: "";
      display: inline-block;
      border-right: 20px solid transparent;
      border-bottom: 20px solid ${theme.colors.green};
      `}
  }
`;

const DetailsContainer = styled.div`
  display: flex;
`;
