interface Partners {
  [key: string]: string;
}

export const partners: Partners = {
  oresys: "Oresys",
  ups: "Université Paris Saclay",
  bleaujob: "Bleaujob",
  au_vieux_campeur: "Au Vieux Campeur",
  crous_versailles: "Crous Versailles",
};
