import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { ButtonWrapper } from "./buttonWrapper";
import { liveOn } from "../config/misc";

export const NavBar: React.FC = () => {
  const { t } = useTranslation();
  const [displaySideBar, setDisplay] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => window.removeEventListener("mousedown", handleClickOutside);
  });

  const isAdmin = useLocation().pathname.startsWith("/admin");

  const isSmallDisplay = useMediaQuery({ query: "(max-width: 1023px)" });

  const pagesUrl = [
    { path: "/", text: "navbar.homepage" },
    { path: "/event", text: "navbar.events" },
    { path: "/photos", text: "navbar.photos" },
    { path: "/partnerships", text: "navbar.partnerships" },
    { path: "/registration", text: "navbar.registration" },
  ];

  const sideBar = (
    <SideBar>
      {pagesUrl.map(({ path, text }) => (
        <NavLink key={path} to={path} onClick={() => setDisplay(false)} exact>
          <span>{t(text)}</span>
        </NavLink>
      ))}
    </SideBar>
  );

  const largeNavBar = (
    <LargeNavBar className="large-navbar">
      {pagesUrl.map(({ path, text }) => (
        <NavLink
          key={path}
          to={path}
          exact={path === "/"}
          activeClassName="route-is-active"
          className="nav-links"
        >
          <span>{t(text)}</span>
        </NavLink>
      ))}
    </LargeNavBar>
  );

  return (
    <NavBarContainer ref={ref} isAdmin={isAdmin}>
      <div className="centered">
        <NavLink to="/" exact>
          <img src="/assets/images/logos/navbar.png" alt="logo white" />
        </NavLink>
        {liveOn && (
          <ButtonWrapper color={"ncv2"}>
            <NavLink to="/live">{t("navbar.live")}</NavLink>
          </ButtonWrapper>
        )}
      </div>
      {isSmallDisplay ? (
        <div>
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => setDisplay(!displaySideBar)}
          />
          {displaySideBar && sideBar}
        </div>
      ) : (
        largeNavBar
      )}
    </NavBarContainer>
  );
};

const NavBarContainer = styled.div<{ isAdmin: boolean }>`
  height: ${({ theme }) => theme.sizes.navbar_height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
  background: ${({ theme, isAdmin }) =>
    isAdmin ? theme.colors.blue1 : theme.colors.header};

  & div {
    color: ${({ theme }) => theme.colors.white};
  }

  & img {
    padding-right: 20px;
    height: 35px;
  }

  & svg {
    // Trick to make the sidebar toggle button easier to click on
    padding: 15px;
    margin: -15px;
  }
`;

const LargeNavBar = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .nav-links {
    color: ${({ theme }) => theme.colors.white};
    padding: 0px 15px;
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const SideBar = styled.div`
  height: 100%;
  min-width: 150px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.footer};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 30px 0px 20px;
  box-shadow: 0px 2px 15px ${({ theme }) => theme.colors.shadow2};

  & a {
    color: ${({ theme }) => theme.colors.white};
    height: 30px;
    padding-bottom: 15px;
  }
`;
