import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Route,
  Redirect,
  NavLink,
  Switch,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { v4 as uuid } from "uuid";

import { getAccessToken } from "../../utils/request";
import { backendSession } from "../../utils/session";
import { Ranking } from "./ranking";
import { RegistrationConfig } from "./registrationConfig";
import { RegistrationManager } from "./registrationManager";

export const AdminRouter: React.FC = () => {
  const isSmallDisplay = useMediaQuery({ query: "(max-width: 675px)" });

  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [isUserLogged, setIsUserLogged] = useState(
    backendSession.accessToken !== undefined
  );

  useEffect(() => {
    if (query.get("state")) {
      const localAuthState = window.localStorage.getItem("auth_state");
      if (localAuthState && query.get("state") === localAuthState) {
        const code = query.get("code");
        if (code) {
          getAccessToken(code)
            .then((res) => {
              backendSession.accessToken = res.accessToken;
              setIsUserLogged(true);
              window.localStorage.setItem("refresh_token", res.refreshToken);
            })
            .catch(() => {
              window.location.replace(
                process.env.REACT_APP_HOST || "http://localhost:3000"
              );
            });
        }
      }
      window.localStorage.removeItem("auth_state");
    } else {
      if (backendSession.accessToken === undefined) {
        const authState = uuid();
        window.localStorage.setItem("auth_state", authState);
        window.location.replace(
          `https://auth.viarezo.fr/oauth/authorize/?redirect_uri=${process.env.REACT_APP_HOST}/admin&client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&response_type=code&state=${authState}&scope=default`
        );
      }
    }
  }, []);

  return isUserLogged ? (
    <Container isSmallDisplay={isSmallDisplay}>
      <Sidebar isSmallDisplay={isSmallDisplay}>
        <NavLink to="/admin/config" activeClassName="route-is-active-left">
          Registration Config
        </NavLink>
        <NavLink to="/admin/manager" activeClassName="route-is-active-left">
          Registration Manager
        </NavLink>
        <NavLink to="/admin/ranking" activeClassName="route-is-active-left">
          Rankings
        </NavLink>
      </Sidebar>
      <Content>
        <Switch>
          <Route component={RegistrationConfig} path="/admin/config" />
          <Route component={RegistrationManager} path="/admin/manager" />
          <Route component={Ranking} path="/admin/ranking" />
          <Redirect to="/admin/config" />
        </Switch>
      </Content>
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div<{ isSmallDisplay: boolean }>`
  display: flex;
  ${({ isSmallDisplay }) =>
    isSmallDisplay &&
    `
    flex-direction: column;
  `}
`;

const Sidebar = styled.div<{ isSmallDisplay: boolean }>`
  ${({ theme, isSmallDisplay }) =>
    !isSmallDisplay &&
    `
    width: 250px;
    min-height: 100vh;
    box-shadow: 0px 100px 20px ${theme.colors.shadow2};
  `}
  background-color: ${({ theme }) => theme.colors.header};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 0;

  & a {
    color: ${({ theme }) => theme.colors.white};
    padding: 15px;
    vertical-align: bottom;
  }
`;

const Content = styled.div`
  flex-grow: 1;
`;
