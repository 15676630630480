import { backendSession } from "./session";
import { Ranking } from "../models/ranking";
import {
  RegistrationConfigResponse,
  RegistrationDates,
} from "../models/registration";

export const getRanking = (
  year: number,
  category: "cvg" | "lead" | "boulder",
  finals: boolean,
  woman: boolean
): Promise<Ranking[]> =>
  backendSession.get("/ranking", {
    params: { year, category, finals, woman },
  });

export const getRegistrationConfig = (): Promise<RegistrationConfigResponse> =>
  backendSession.get("/registration/config");

export const updateRegistrationDates = (
  dates: Partial<RegistrationDates>
): Promise<RegistrationConfigResponse> =>
  backendSession.put("/registration/config", { data: { dates } });

export const getAccessToken = (
  code: string
): Promise<{ accessToken: string; refreshToken: string }> =>
  backendSession.post("/auth", { data: { code } });
