import React from "react";
import styled from "styled-components";

export const Banner: React.FC<{ title: string }> = ({ title }) => (
  <BannerContainer>
    <BannerTitle> {title} </BannerTitle>
  </BannerContainer>
);

const BannerContainer = styled.div`
  width: 100%;
  background: linear-gradient(
    190deg,
    ${({ theme }) => theme.colors.gradient1},
    ${({ theme }) => theme.colors.gradient2}
  );
`;

const BannerTitle = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  font-family: ${({ theme }) => theme.fonts.bold};
`;
