import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { acronyms } from "./config/events";
import { liveOn } from "./config/misc";
import { RegistrationContext } from "./config/registration/context";
import { ProtectedRoute } from "./components/protectedRoute";

import { EventsOverview } from "./pages/eventsOverview";
import { EventDescription } from "./pages/eventsDescriptions";
import { Partnerships } from "./pages/partnerships";
import { Photos } from "./pages/photos";
import { Registration } from "./pages/registration/registration";
import { Form } from "./pages/registration/form";
import { UserPage } from "./pages/registration/userPage";
import { Live } from "./pages/live";
import { Rankings } from "./pages/ranking";
import { NotFound } from "./pages/notFound";
import { AdminRouter } from "./pages/admin";

export const AppRouter: React.FC = () => {
  const {
    registrations,
    shotgunConfig,
    shotgunGuestsConfig,
    registrationsClosed,
  } = useContext(RegistrationContext);

  return (
    <Switch>
      <Route exact path="/not_found" component={NotFound} />
      <Redirect exact from="/event" to="/event/ncv" />
      {liveOn && <Route exact path="/live" component={Live} />}
      <Route
        exact
        path={acronyms.map((acronym) => `/event/${acronym}`)}
        component={EventDescription}
      />
      <Route exact path="/partnerships" component={Partnerships} />
      <Route exact path="/photos" component={Photos} />
      <Route
        exact
        path={["/registration", "/registration/guests"]}
        component={Registration}
      />
      {[shotgunConfig, shotgunGuestsConfig].map((config) => (
        <ProtectedRoute
          condition={
            (config.registrationsOn && !registrationsClosed) as boolean
          }
          exact
          key={config.prefix}
          path={Object.keys(registrations).map(
            (acronym) => `/registration${config.prefix}/event/${acronym}`
          )}
          component={Form}
          fallback={<Redirect to="/not_found" />}
        />
      ))}
      {
        <ProtectedRoute
          condition={
            ((shotgunConfig && shotgunConfig.registrationsOn) ||
              (shotgunGuestsConfig &&
                shotgunGuestsConfig.registrationsOn)) as boolean
          }
          path="/registration/user/:email"
          component={UserPage}
          fallback={<Redirect to="/not_found" />}
        />
      }
      <Route exact path="/ranking" component={Rankings} />
      <Route path="/admin" component={AdminRouter} />
      <Route exact path="/" component={EventsOverview} />
      <Redirect to="/not_found" />
    </Switch>
  );
};
