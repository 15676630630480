import React from "react";

import { Banner } from "../../components/banner";

export const Ranking: React.FC = () => (
  <div>
    <Banner title="Rankings" />
    <div className="margin">
      <i>Work in progress</i>
    </div>
  </div>
);
