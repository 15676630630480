import React, { useContext } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { Banner } from "../../components/banner";
import { EditionCard, RegistrationContainer } from "./registration";
import { ColoredSpan } from "../../components/misc";
import { ButtonWrapper } from "../../components/buttonWrapper";
import { RegistrationContext } from "../../config/registration/context";

export const Item: React.FC<{
  validated?: boolean;
  text: string;
}> = ({ validated, text }) => {
  return (
    <ColoredSpan color={validated ? "green" : "red"}>
      <FontAwesomeIcon icon={validated ? faCheckCircle : faTimesCircle} />{" "}
      {text}
    </ColoredSpan>
  );
};

export const UserPage: React.FC = () => {
  const { t } = useTranslation();
  const { email } = useParams<{ email: string }>();

  const { registrations } = useContext(RegistrationContext);

  const fullName = "Manu Cornu"; // TODO: Get fullName and status from the backend
  const status: { [key: string]: any } = {
    ncv: {
      paid: false,
      card_validated: true,
      rank: 0,
    },
    cvg: {
      paid: false,
      rank: 23,
    },
  };

  const eventsRegistered = Object.keys(status)
    .sort()
    .reverse()
    .filter((key) => status[key].rank >= 0);
  const eventsToPay = Object.keys(status).filter(
    (key) => status[key].rank === 0 && !status[key].paid
  );
  const priceSecondEvent =
    eventsToPay.length === 1 &&
    registrations["ncv_cvg"].price - registrations[eventsToPay[0]].price;
  const isSecond =
    eventsRegistered.length === 2 && (status.ncv.paid || status.cvg.paid);
  const price = isSecond
    ? registrations["ncv_cvg"].price -
      registrations[status.ncv.paid ? "ncv" : "cvg"].price
    : registrations[eventsToPay.join("_")].price;

  const pay = () => {
    console.log(`Paying ${price} to register to ${eventsToPay}`); // TODO: Implement it
  };

  return (
    <div>
      <Banner title={t("navbar.registration")} />
      <div className="margin">
        <h3> Merci d'avoir rempli le formulaire {fullName} ! </h3>
        <p>
          Tu t'es inscrit avec l'adresse mail{" "}
          <ColoredSpan color="green">{email}</ColoredSpan>
        </p>
        <RegistrationContainer>
          {eventsRegistered.map((acronym) => (
            <EditionCard acronym={acronym} key={acronym}>
              <h3>Place {acronym.toUpperCase()}</h3>
              <ItemsContainer>
                {status[acronym].card_validated !== undefined && (
                  <Item
                    validated={status[acronym].card_validated}
                    text={
                      status[acronym].card_validated
                        ? "Carte étudiante validée"
                        : "Carte étudiante pas encore validée"
                    }
                  />
                )}
                <Item
                  text={status[acronym].rank === 0 ? "Inscrit" : "Non inscrit"}
                  validated={status[acronym].rank === 0}
                />
                {status[acronym].rank === 0 ? (
                  <Item
                    text={status[acronym].paid ? "Payé" : "Non payé"}
                    validated={status[acronym].paid}
                  />
                ) : (
                  <p>
                    T'es n°{status[acronym].rank} en file d'attente. La file
                    d'attente évolue rapidement, reviens régulièrement !
                  </p>
                )}
              </ItemsContainer>
            </EditionCard>
          ))}
        </RegistrationContainer>
        {eventsToPay.length && (
          <div>
            <h3>
              Tu peux désormais payer ta place{" "}
              {eventsToPay.map((s) => s.toUpperCase()).join(" + ")}.
            </h3>
            {eventsToPay.length < eventsRegistered.length &&
              !status.ncv.paid &&
              !status.cvg.paid && (
                <p>
                  NB: Tu pourras bien payer ta 2ème place à {priceSecondEvent}€
                  une fois la file d'attente passée.
                </p>
              )}
            <ButtonWrapper>
              <a onClick={pay}>Payer ({price}€)</a>
            </ButtonWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

const ItemsContainer = styled.div`
  justify-content: left;
`;
