import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

interface BodyRequestConfig {
  data?: any;
  config?: AxiosRequestConfig;
}

interface BodyLessRequestConfig {
  params?: any;
  config?: AxiosRequestConfig;
}

export class Session {
  private api: AxiosInstance;
  private baseURL: string;
  private _accessToken?: string;

  constructor(url: string, headers = {}) {
    this.api = axios.create({
      baseURL: url,
      headers,
      responseType: "json",
    });
    this.baseURL = url;
  }

  public get accessToken(): string | undefined {
    return this._accessToken;
  }

  public set accessToken(value: string | undefined) {
    this._accessToken = value;
    this.api = axios.create({
      baseURL: this.baseURL,
      headers: {
        Authorization: `Basic ${value}`,
      },
      responseType: "json",
    });
  }

  public async get<T>(
    url: string,
    { params, config }: BodyLessRequestConfig = { config: {} }
  ): Promise<T> {
    const res = await this.api.get(url, {
      params,
      ...config,
    });
    return res.data as T;
  }

  public async post<T>(
    url: string,
    { data, config }: BodyRequestConfig = { config: {} }
  ): Promise<T> {
    const res = await this.api.post(url, data, config);
    return res.data as T;
  }

  public async put<T>(
    url: string,
    { data, config }: BodyRequestConfig = { config: {} }
  ): Promise<T> {
    const res = await this.api.put(url, data, config);
    return res.data as T;
  }

  public async delete<T>(
    url: string,
    { params, config }: BodyLessRequestConfig = { config: {} }
  ): Promise<T> {
    const res = await this.api.delete(url, {
      params,
      ...config,
    });
    return res.data as T;
  }
}

export const backendSession = new Session(
  process.env.REACT_APP_BACKEND_URL || "http://localhost:8000"
);
