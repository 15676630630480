import React from "react";
import styled from "styled-components";
import { IColors } from "../styles/theme";

export const ButtonWrapper: React.FC<{
  color?: keyof IColors;
  transparentBackground?: boolean;
}> = ({ color, transparentBackground, children }) => {
  return (
    <StyledButtonWrapper
      color={color}
      transparentBackground={transparentBackground}
    >
      {children}
    </StyledButtonWrapper>
  );
};

const StyledButtonWrapper = styled.div<{
  color?: keyof IColors;
  transparentBackground?: boolean;
}>`
  cursor: pointer;
  z-index: 2;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ transparentBackground }) =>
      transparentBackground ? "1px solid" : "none"};
    height: ${({ theme }) => theme.sizes.button_height};
    border-radius: ${({ theme }) => theme.sizes.border_radius};
    margin: 6px 0px;
    padding: 0px 20px;
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 15px;
    color: ${({ theme, color, transparentBackground }) =>
      transparentBackground
        ? theme.colors[color || "ncv1"]
        : theme.colors.white};
    background-color: ${({ theme, color, transparentBackground }) =>
      transparentBackground ? "transparent" : theme.colors[color || "ncv1"]};
    :hover {
      background-color: ${({ theme, color }) => theme.colors[color || "ncv1"]};
      color: ${({ theme }) => theme.colors.white};
      filter: ${({ transparentBackground }) =>
        transparentBackground ? "brightness(100%)" : "brightness(115%)"};
    }
  }
`;
