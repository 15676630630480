import React, { useContext, useState, useEffect } from "react";

import { RegistrationContext } from "../../config/registration/context";
import { RegistrationDates } from "../../models/registration";
import { ButtonWrapper } from "../../components/buttonWrapper";
import {
  CheckboxContainer,
  FormContainer,
} from "../../components/formContainer";
import { Banner } from "../../components/banner";
import { getRegistrationConfig } from "../../utils/request";

export const RegistrationConfig: React.FC = () => {
  const { updateRegistration } = useContext(RegistrationContext);
  const handleSubmitDates: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (config) {
      updateRegistration(config);
    }
  };
  const [config, setConfig] = useState<Partial<RegistrationDates>>();
  const onChange =
    (
      key: keyof RegistrationDates
    ): React.ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      setConfig((_config) => ({
        ..._config,
        [key]:
          key === "registrationsClosed"
            ? event.target.checked
            : event.target.value,
      }));
    };

  useEffect(() => {
    getRegistrationConfig().then(setConfig);
  }, []);
  return (
    <div>
      <Banner title="Registration Config" />
      <div className="margin">
        <FormContainer onSubmit={handleSubmitDates}>
          <label>Next Edition Month</label>
          <input
            type="month"
            value={config?.nextEditionMonth || ""}
            onChange={onChange("nextEditionMonth")}
          />
          <label>NCV Date</label>
          <input type="date" value={config?.ncv} onChange={onChange("ncv")} />
          <label>CVG Date</label>
          <input type="date" value={config?.cvg} onChange={onChange("cvg")} />
          <label>Guest Shotgun Date</label>
          <input
            type="datetime-local"
            value={config?.guestShotgun}
            onChange={onChange("guestShotgun")}
          />
          <label>Global Shotgun Date</label>
          <input
            type="datetime-local"
            value={config?.globalShotgun}
            onChange={onChange("globalShotgun")}
          />
          <CheckboxContainer>
            <input
              type="checkbox"
              onChange={onChange("registrationsClosed")}
              checked={config?.registrationsClosed}
            />
            <label>Registrations Closed</label>
          </CheckboxContainer>
          <ButtonWrapper>
            <input type="submit" value="Submit" />
          </ButtonWrapper>
        </FormContainer>
      </div>
    </div>
  );
};
