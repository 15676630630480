import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { SocialNetworks } from "./socialNetworks";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <a href="mailto:nuitcentraleverticale@gmail.com">
        <p>{t("footer.contact_us")}</p>
        nuitcentraleverticale@gmail.com
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.google.fr/maps/dir//3+Rue+Joliot+Curie,+91190+Gif-sur-Yvette/@48.7088515,2.0939664,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e67f5ab2295b95:0x12eabc8deefcf345!2m2!1d2.1640062!2d48.7087594!3e0?hl=fr"
      >
        <p>{t("footer.find_us")}</p>3 rue Joliot Curie, 91190 Gif-sur-Yvette
      </a>
      <SocialNetworks />
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.footer};
  padding: 30px;
  display: flex;
  flex-direction: column;

  & > * {
    padding: 10px;
  }

  & a {
    color: ${({ theme }) => theme.colors.grey0};
    overflow-x: hidden;
  }
`;
