import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { IColors } from "../styles/theme";

export const SocialNetworks: React.FC<{
  color?: keyof IColors;
}> = ({ color }) => {
  return (
    <SocialNetworksContainer color={color}>
      <a href="https://www.facebook.com/ncv.nuitcentraleverticale/">
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a href="https://www.instagram.com/ncvofficiel/">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.youtube.com/user/nuitcentralevertical">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
      <a href="https://www.linkedin.com/company/nuit-centrale-verticale/">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </SocialNetworksContainer>
  );
};

const SocialNetworksContainer = styled.div<{ color?: keyof IColors }>`
  width: 150px;
  display: flex;
  justify-content: space-between;
  & a {
    color: ${({ color, theme }) => theme.colors[color || "white"]};
    font-size: 25px;
  }
`;
