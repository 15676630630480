import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import "./styles/App.css";
import { theme } from "./styles/theme";

import { ScrollToTop } from "./components/misc";
import { NavBar } from "./components/navBar";
import { Footer } from "./components/footer";

import {
  RegistrationContext,
  useRegistrationContext,
} from "./config/registration/context";
import { AppRouter } from "./App.router";

const App: React.FC = () => {
  const registrationValues = useRegistrationContext();
  return (
    <ThemeProvider theme={theme}>
      <RegistrationContext.Provider value={registrationValues}>
        <Router>
          <ScrollToTop />
          <PageContainer>
            <div>
              <NavBar />
              <AppRouter />
            </div>
            <Footer />
          </PageContainer>
        </Router>
      </RegistrationContext.Provider>
    </ThemeProvider>
  );
};

const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default App;
