import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ButtonWrapper } from "../components/buttonWrapper";

import { acronyms, eventValues } from "../config/events";
import { IColors } from "../styles/theme";
import { ColoredSpan } from "../components/misc";
import { Link } from "react-router-dom";

const EventOverview: React.FC<{ acronym: eventValues }> = ({ acronym }) => {
  const { t } = useTranslation();

  const color1 = `${acronym}1` as keyof IColors;
  const color2 = `${acronym}2` as keyof IColors;

  const [subtitle1, subtitle2] = t(`overview.${acronym}.subtitle`).split("|");

  return (
    <EventOverviewContainer className="event-overview">
      <div className="presentation">
        <img
          src={`assets/images/overview/gradiants/${acronym}.png`}
          alt="event logo"
        />
        <TextContainer>
          <h2>
            <ColoredSpan color={color1}>
              {t(`overview.${acronym}.title`)}
            </ColoredSpan>
            <ColoredSpan color={color2}>.</ColoredSpan>
          </h2>
          <h3>
            <ColoredSpan color={color1}>{subtitle1}</ColoredSpan>
            <ColoredSpan color={color2}>{subtitle2}</ColoredSpan>
          </h3>
          <p>{t(`overview.${acronym}.description`)}</p>
          <div className="centered buttons">
            <ButtonWrapper color={color2}>
              <Link to={`/event/${acronym}`}>{t("read_more")}</Link>
            </ButtonWrapper>
            {acronym === "vt" ? (
              <ButtonWrapper color={color2}>
                <a href="mailto:nuitcentraleverticale@gmail.com">
                  {t("footer.contact_us")}
                </a>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper color={color2}>
                <Link to="/registration">{t("navbar.registration")}</Link>
              </ButtonWrapper>
            )}
          </div>
        </TextContainer>
      </div>
      <FullScreenPhoto acronym={acronym}>
        <SvgContainer width="84.28" height="29.86">
          <path d="m 0 0 q 8.28 0 14.14 5.86 l 20 20 q 8 8 16 0 l 20 -20 q 5.86 -5.86 14.14 -5.86" />
        </SvgContainer>
        <h1>{t(`overview.${acronym}.slogan`)}</h1>
      </FullScreenPhoto>
    </EventOverviewContainer>
  );
};

export const EventsOverview: React.FC = () => (
  <div>
    {acronyms.map((acronym) => (
      <EventOverview key={acronym} acronym={acronym as eventValues} />
    ))}
  </div>
);

const FullScreenPhoto = styled.div<{ acronym: eventValues }>`
  height: 45vw;
  text-align: center;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: ${({ acronym }) =>
    `url("/assets/images/overview/backgrounds/${acronym}.jpg") center/100vw no-repeat`};
  box-shadow: inset 0px 15px 15px ${({ theme }) => theme.colors.shadow2};
  @media screen and (max-width: 675px) {
    height: 100vw;
    background-size: auto 100%;
  }

  & h1 {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.light};
    text-shadow: 0px 0px 10px black;
  }
`;

const SvgContainer = styled.svg`
  position: absolute;
  top: 0px;
  margin: auto;
  width: auto;
  fill: ${({ theme }) => theme.colors.background};
`;

const EventOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  & .presentation {
    display: flex;
    align-items: center;
    margin: 20px auto;
    max-width: 100vw;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }

  & .buttons {
    & * + * {
      margin-left: 10px;
    }
  }

  & img {
    height: 380px;
    width: auto;
    margin: 0px 15px;

    @media screen and (max-width: 1100px) {
      height: 300px;
    }
  }
`;

const TextContainer = styled.div`
  width: 650px;
  margin: 0px 15px;
  display: flex;
  flex-direction: column;
  & p {
    color: ${({ theme }) => theme.colors.grey1};
  }

  & h2 {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.bold};
  }

  @media screen and (max-width: 675px) {
    width: 90%;
    text-align: center;
  }
`;
